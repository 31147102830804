<div id="field1">
    <div id="field2">
        <div id="field3">
            <div class="page-bg"></div>

            <div class="animation-wrapper">
                <div class="particle particle-1"></div>
                <div class="particle particle-2"></div>
                <div class="particle particle-3"></div>
                <div class="particle particle-4"></div>
            </div>

            <div class="wrapper">
                <header>
                    <a class="logo" routerLink="" data-aos="fade-down">
                        <img id="logo-img" src="../../assets/img/logo.png">
                        <span id="logo-title">Agence Web Rovere</span>
                    </a>
                    <nav>
                        <ul class="nav-ul">
                            <li>
                                <a class="flex" routerLink="" fragment="realisations">

                                    <img class="icon-star" src="../../assets/img/etoile.png" width="50px">
                                    <span>Réalisations</span>

                                </a>
                            </li>
                            <li>
                                <a href="../../assets/cv/CV-Alex-ROVERE-09-2021.pdf" download="CV-Alex-ROVERE"
                                    class="flex">
                                    <img class="icon-star" src="../../assets/img/etoile.png" width="50px">
                                    <span>CV</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </header>
                <section class="middle">
                    <h2>Bienvenue sur mon Portfolio</h2>
                    <p class="intro">Passionné d'informatique et de nouvelles technologies, je me
                        suis reconverti dans le domaine du développement web.<br></p>
                    <p class="intro"> Ayant obtenu mon diplome récemment, je suis à la recherche de nouveaux
                        projets.<br>
                        Manager de formation, j’apporterai mon adaptabilité et mon
                        sens de l'organisation pour être efficient au plus vite.
                    </p>
                    <p>Des questions, un projet à me proposer ?</p>
                    <a routerLink="contact"><button id="btn-contact" data-aos="fade-right">Contactez-moi</button></a>
                </section>
            </div>
        </div>
    </div>
</div>
<section id="competences" class="clearfix">
    <section class="clearfix">
        <figure class="icone-competences">
            <a href="https://abi.agence-web-rovere.fr/" class="icon-mobile"></a>
        </figure>
        <header>
            <a href="https://abi.agence-web-rovere.fr/">
                <h3>HTML / CSS / JS</h3>
            </a>
        </header>
        <p class="text-secondary">Développement de la structure d'une page web avec les langages natifs, respectivement
            pour la structure, le style et l'intéractivité </p>
    </section>
    <section class="clearfix">
        <figure class="icone-competences">
            <a href="https://edward.agence-web-rovere.fr/" class="icon-multi"></a>
        </figure>
        <header>
            <a href="https://edward.agence-web-rovere.fr/">
                <h3>Angular</h3>
            </a>
        </header>
        <p class="text-secondary">Framework front-end utilisant Typescript pour réaliser des sites dynamiques avec un
            code maintenable et ce, avec une rapidité de production accru</p>
    </section>
    <section class="clearfix">
        <figure class="icone-competences">
            <a href="https://agence-web-rovere.fr/" class="icon-web-design"></a>
        </figure>
        <header>
            <a href="https://agence-web-rovere.fr/">
                <h3>Symfony</h3>
            </a>
        </header>
        <p class="text-secondary">Framework back-end utilisant PHP, pour réaliser des applications plus complexes
            demandant un accès à une base de données et d'autres fonctionnalités</p>
    </section>
    <section class="clearfix">
        <figure class="icone-competences">
            <a href="../../assets/cv/CV-Alex-ROVERE-09-2021.pdf" class="icon-web-dev"></a>
        </figure>
        <header>
            <h3>Soft skills</h3>
        </header>
        <p class="text-secondary">Dynamique et curieux, je suis sans cesse à la recherche de m'améliorer, couplé à mon
            excellent relationnel, je met tout en oeuvre pour mener à bien mes projets</p>
    </section>
</section>

<section id="realisations">
    <h2>Mes réalisations</h2>
    <div class="wrapper-middle">
        <div class="flex-row">
            <div class="card" data-aos="flip-right">
                <a href="https://abi.agence-web-rovere.fr/">
                    <h4>Projet ABI</h4>
                    <img class="img-sites" src="../../assets/img/abi.png">
                </a>
                <p class="text-realisations">Premier projet réalisé en formation, c'est un site dynamique avec une
                    connexion à un back-office, il
                    a
                    été réalisé en <strong>PHP</strong> et intègre une gestion
                    des comptes selon le niveau d'accès</p>
            </div>
            <div class="card" data-aos="fade-in">
                <a href="https://edward.agence-web-rovere.fr/">
                    <h4>Projet Edward comics</h4>
                    <img class="img-sites" src="../../assets/img/edward.png">
                </a>
                <p class="text-realisations">Second projet, un site de vente en ligne de comics, avec système de compte
                    utilisateur et de requete
                    à un
                    API externe, le site à été réalisé sous <strong>Angular</strong>
                    avec plusieurs version de back-end (Firebase & PHPMyAdmin)
                </p>
            </div>
            <div class="card" data-aos="flip-left">
                <a href="https://agence-web-rovere.fr/">
                    <h4>Projet Olymp'Fit</h4>
                    <img class="img-sites" src="../../assets/img/olymp.png">
                </a>
                <p class="text-realisations">Site réalisé lors de mon stage pour une cliente, c'est un site de vente de
                    programme sportif en
                    version
                    PDF, il lui sert également en tant que blog
                    pour donner des conseils à ses clients, le site à été réalisé avec <strong>Angular</strong> couplé à
                    <strong>Symfony</strong> en API grace à APIplatform, un back-office est également intégré
                    pour que ma cliente puisse ajouter du contenu.
                </p>
            </div>
        </div>
    </div>

</section>