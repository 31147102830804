<div id="field1">
    <div id="field2">
        <div id="field3">
            <div class="page-bg"></div>

            <div class="animation-wrapper">
                <div class="particle particle-1"></div>
                <div class="particle particle-2"></div>
                <div class="particle particle-3"></div>
                <div class="particle particle-4"></div>
            </div>
            <div class="wrapper">
                <header>
                    <a class="logo" routerLink="">
                        <img src="../../assets/img/logo.png">
                        <span>Agence Web Rovere</span>
                    </a>
                    <nav>
                        <ul class="nav-ul">
                            <li>
                                <a class="flex" routerLink="" fragment="realisations">
                                    <img class="icon-star" src="../../assets/img/etoile.png" width="50px">
                                    <span>Réalisations</span>
                                </a>
                            </li>
                            <li>
                                <a href="../../assets/cv/CV-Alex-ROVERE-09-2021.pdf" download="CV-Alex-ROVERE"
                                    class="flex">
                                    <img class="icon-star" src="../../assets/img/etoile.png" width="50px">
                                    <span>CV</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </header>
                <!-- FORM -->
                <section>

                    <h2>
                        Contactez-moi
                    </h2>

                    <p class="text-center">
                        Une proposition d'emploi ? Un projet ?
                    </p>
                    <!-- Form -->
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="container d-around">
                            <div class="form-group column">
                                <!-- Label -->
                                <label class="form-label" for="contactName">
                                    Nom
                                </label>
                                <!-- Input -->
                                <input class="form-control" id="contactName" type="text" placeholder="Votre nom"
                                    formControlName="name">
                                <div *ngIf="contactForm.controls['name'].invalid && (contactForm.controls['name'].dirty || contactForm.controls['name'].touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="contactForm.controls['name'].errors?.maxlength">
                                        255 caracteres maximum.
                                    </div>
                                    <div *ngIf="contactForm.controls['name'].errors?.required">
                                        Votre nom est obligatoire !
                                    </div>
                                    <div *ngIf="contactForm.controls['name'].errors?.pattern">
                                        Lettres uniquement.
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="d-center">
                                    <div class="form-group column">
                                        <!-- Label -->
                                        <label class="form-label" for="contactEmail">
                                            Email
                                        </label>
                                        <!-- Input -->
                                        <input class="form-control" id="contactEmail" type="email"
                                            placeholder="hello@domain.com" formControlName="email">
                                        <div *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched)"
                                            class="alert alert-danger">
                                            <div *ngIf="contactForm.controls['email'].errors?.maxlength">
                                                255 caracteres maximum.
                                            </div>
                                            <div *ngIf="contactForm.controls['email'].errors?.required">
                                                Votre email est obligatoire !
                                            </div>
                                            <div *ngIf="contactForm.controls['email'].errors?.email">
                                                Mauvais format (email@email.mail).
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group column">
                            <!-- Label -->
                            <label class="form-label column" for="contactMessage">
                                Votre demande
                            </label>
                            <!-- Input -->
                            <textarea class="form-control" id="contactMessage" rows="5"
                                placeholder="Que puis-je pour vous ?" formControlName="message"></textarea>
                            <div *ngIf="contactForm.controls['message'].invalid && (contactForm.controls['message'].dirty || contactForm.controls['message'].touched)"
                                class="alert alert-danger">
                                <div *ngIf="contactForm.controls['message'].errors?.maxlength">
                                    3000 caracteres maximum.
                                </div>
                                <div *ngIf="contactForm.controls['message'].errors?.required">
                                    Votre message est obligatoire.
                                </div>
                                <div *ngIf="contactForm.controls['message'].errors?.pattern">
                                    Chiffres et Lettres uniquement.
                                </div>
                            </div>
                        </div>
                        <div class="d-center column">
                            <div *ngIf="mailSend" class="alert-success text-center">
                                Votre email a bien été envoyé, merci !
                            </div>
                            <!-- Submit -->
                            <button type="submit" value="Send" class="btn-contact">
                                Envoyer
                            </button>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    </div>
</div>
<div class="separate"></div>