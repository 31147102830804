<footer>
    <div class="flex-row">
        <ul class="flex-footer">
            <a routerLink="">
                <li class="text-third">Accueil</li>
            </a>
            <a routerLink="contact">
                <li class="text-third">Me contacter</li>
            </a>
        </ul>
        <p>Site réalisé par <strong><a class="text-secondary"
                    href="https://www.linkedin.com/in/alex-rovere-906089207/">Alex ROVERE<span><img class="logo-linked"
                            src="../../assets/img/linked.png"> </span></a></strong></p>
        <ul class="flex-footer">
            <a href="../../assets/cv/CV-Alex-ROVERE-09-2021.pdf" download="CV-Alex-ROVERE">
                <li class="text-third">Voir mon CV</li>
            </a>
            <a routerLink="" fragment="realisations">
                <li class="text-third">Mes réalisations</li>
            </a>
        </ul>
    </div>
</footer>