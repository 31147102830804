import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailService } from '../service/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  contactForm!: FormGroup;
  errorMessage!: String;
  data!: object;
  mailSend = false;

  constructor(
    private formBuilder: FormBuilder,
    private emailService: EmailService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z-éèà ]+$/),
          Validators.maxLength(255),
        ],
      ],
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(255)],
      ],
      message: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[0-9a-zA-Z-éèà ]+$/),
          Validators.maxLength(3000),
        ],
      ],
    });
  }

  onSubmit() {
    let data = this.contactForm.value;
    this.data = data;
    console.log(this.data);
    this.sendEmail(this.data);
  }

  sendEmail(data: object) {
    let dataToSend = JSON.stringify(data);
    this.emailService.getUrlEmail(dataToSend).subscribe(
      (response: any) => {
        if (response['success']) {
          this.contactForm.reset();
          this.mailSend = true;
        } else {
          console.log('Erreur : ' + response['msg']);
        }
      },
      (error) => console.log(error)
    );
  }
}
