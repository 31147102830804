import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpClient) {}

  API_SERVER = 'https://admin.agence-web-rovere.fr/php';

  getUrlEmail(dataToSend: string) {
    return this.http.post(`${this.API_SERVER}/email.php`, dataToSend);
  }
}
